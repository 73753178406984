<template>
  <ion-page>
    <ion-content>
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button default-href="/news" />
          </ion-buttons>
          <ion-title color="white">
            {{ $t('motoinsideappname') }}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content v-if="!$apollo.loading">
        <div class="container max-w-xl mx-auto">
          <news-card :news-item="news" :show-all="true" />
        </div>
      </ion-content>
    </ion-content>
  </ion-page>
</template>

<script>
    import { GET_SINGLE_NEWS } from "@/graphql/queries";
    import NewsCard from './NewsCard.vue';

    export default {
      metaInfo() {
        if (!this.news) return {};

        return {
          title: `${this.news.title} | Moto Inside`,
          meta: [
            { 'property': 'og:title', 'content': `${this.news.title} | Moto Inside`, 'vmid': 'og:title'}
          ]
        }
      },
      components: {
        NewsCard
      },

      apollo: {
        news: {
          query: GET_SINGLE_NEWS,
          variables()  {
            return {
              id: this.$route.params.id,
            }
          },
          update: data => data.newsItem
        }
      }
    }
</script>
